import React from 'react';

const Modal = ({ children, closeConfirmation }) => {
  return (
    <div className="modal fixed w-full h-full top-0 left-0 z-50 flex items-center justify-center">
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
      <div className="relative modal-container bg-white mx-auto rounded shadow-lg shadow-md w-4/5 sm:w-[600px] p-4 gap-3 flex flex-col">
        {children}
        {closeConfirmation ?(
          <div className="justify-center items-center cross-icon cursor-pointer my-0 mx-[!important] absolute top-[-9px] right-[-9px] rounded-[50px] bg-light-solid-color-extra-card-background w-8 h-8 overflow-hidden shrink-0 flex flex-row items-start justify-end p-1.5 box-border z-[1]" onClick={closeConfirmation}>
            <img
              className="relative w-5"
              alt=""
              src="/cross.svg"
            />
          </div>):''
        }
      </div>
    </div>
  );
};

export default Modal;
