import { useState } from "react";
import { useGoogleLogin } from '@react-oauth/google';

const Confirmation = ({closeConfirmation, email, setEmail, startChatbotCreation}) => {
  	const [error, setError] = useState('');
	const handleInputChange = (event) => {
		setEmail(event.target.value);
		setError('');
	};
	const isValidEmail = (email) => {
	  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	  return emailRegex.test(email);
  	};

	const handleSubmit = () => {
		if (!isValidEmail(email)) {
		  setError('Invalid email. Please enter a valid Email address');
		  return;
		}

		closeConfirmation()
		startChatbotCreation()
	};

	async function handleGoogleSuccess(credentials_response){
		const token = credentials_response.access_token
		let response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
	      headers: { "Authorization": `Bearer ${token}` },
	    })
	    let json = await response.json()
		setEmail(json.email)
	}

	const loginWithTwitter = () => {
        let twitterWindow = window.open('/twitter/', "Twitter Login","width=400,height=600")
        window.addEventListener('message', function (event) {
            if (event.source === twitterWindow) {
                let receivedData = event.data;
                if (receivedData && receivedData.email) {
                    let userEmail = receivedData.email;
                    setEmail(userEmail)
                }
            }
        });
    }

	const login = useGoogleLogin({
	  onSuccess: tokenResponse => handleGoogleSuccess(tokenResponse),
	});
	const handleKeyPress = (event) => {
	    if (event.key === 'Enter') {
	      handleSubmit();
	    }
	};
                        
	return (
		<>
        <div className="self-stretch flex items-start justify-center md:justify-start gap-y-2">
            <div className="self-stretch relative font-semibold leading-5">
             We'll create a custom GPT with your website and deliver it straight to your inbox in minutes.
            </div>
      	</div>
	    <div className="self-stretch flex flex-col md:flex-row items-start justify-start">
            <div className="self-stretch flex rounded-b-none rounded-t-md md:rounded-l-md md:rounded-r-none bg-light-solid-color-extra-card-background items-center justify-start border-2 border-solid border-light-solid-color-extra-divider w-[98.5%] md:w-3/4">
                <input
                  type="text"
                  className={`rounded text-lg w-full py-2 px-3 text-gray-700 leading-tight border-none ${error ? 'border-1 border-red-500' : ''}`}
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                />
            </div>
		    <div onClick={handleSubmit}
		    className="self-stretch items-center py-2 px-1 md:px-3 rounded-b-md rounded-t-none md:rounded-r-md md:rounded-b-none md:rounded-l-none  bg-light-solid-color-primary-primary-500-base cursor-pointer text-light-solid-color-extra-card-background min-w-[70px] text-base md:w-1/4">
	            <div className="flex justify-center">
		            <div className="relative font-medium flex items-center gap-x-1 md:py-1">
		              <div className="font-semibold">Get My Demo</div><div className="text-lg font-semibold"><img
					    className="relative w-5"
					    alt=""
					    src="/chevronright.svg"
					  /></div>
		            </div>
	            </div>
	        </div>
        </div>
      	{error && (
           	<div className="text-red-500">
            	{error}
            </div>
      	)}
	    <div className="self-stretch relative text-light-typography-color-body-text text-smi md:text-lg">
	        Your email is safe: no selling, no spam, just service.
	    </div>
      	<div className="self-stretch flex flex-col items-center justify-start text-center text-mini text-light-typography-color-body-text">
            <div className="self-stretch relative font-semibold pb-1">
              OR continue with:
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-0 px-[25px] gap-[16px] mq450:flex-wrap">
				<button className="cursor-pointer p-0 bg-[transparent] rounded flex flex-row items-center justify-center border-[1px] border-solid border-light-solid-color-primary-primary-500-base" onClick={login}>
					<div className="flex flex-row items-start justify-start py-1.5 px-3.5 gap-[6px]">
					  <img
					    className="relative w-4 h-4 min-h-[16px]"
					    alt=""
					    src="/google.svg"
					  />
					  <div className="hidden md:block relative text-smi tracking-[0.43px] leading-[16px] font-medium font-light-button-3-button-small text-light-solid-color-primary-primary-500-base text-left">
					    Google
					  </div>
					</div>
				</button>
				<button className="cursor-pointer p-0 bg-[transparent] rounded flex flex-row items-center justify-center border-[1px] border-solid border-light-solid-color-primary-primary-500-base" onClick={loginWithTwitter}>
					<div className="flex flex-row items-start justify-start py-1.5 px-3.5 gap-[6px]">
					  <img
					    className="relative w-4 h-4 overflow-hidden shrink-0 min-h-[16px]"
					    alt=""
					    src="/twitter.svg"
					  />
					  <div className="hidden md:block relative text-smi tracking-[0.43px] leading-[16px] font-medium font-light-button-3-button-small text-light-solid-color-primary-primary-500-base text-left">
					    Twitter
					  </div>
					</div>
				</button>
            </div>
        </div>
		</>
	);
};

export default Confirmation;
