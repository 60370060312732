import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { Tooltip } from 'react-tooltip'


const StatusChecker = () => {
	const statusText = {
		'processing': {text:"In Progress", bgColor: "bg-light-opacity-color-warning-warning-16", textColor: 'text-light-solid-color-warning-warning-500-base', description: 'Your custom chatbot is being created. A link will be sent to you once it is ready.' },
		'creating_sitemap': {text:'Creating Sitemap', bgColor: "bg-light-opacity-color-warning-warning-16", textColor: 'text-light-solid-color-warning-warning-500-base', description: 'Your sitemap is being created, once completed we will move forward with chatbot creation.'},
		'ready': {text: 'Ready', bgColor:'bg-light-opacity-color-success-success-16', textColor: 'text-light-solid-color-success-success-500-base', description: 'You can now talk to your CustomGPT chatbot. This demo chatbot will self destruct in 24 hours.' },
		'failed': {text: 'Failed', bgColor: 'bg-red-500', textColor: 'text-white', description: 'Your Chatbot Creation Failed.'}
	}
	let { email } = useParams();
	const [chatbots, setChatbots] = useState([])
	const navigate = useNavigate()
	const [mounted, setMounted] = useState(false)
	const [intervalId, setIntervalId] = useState(null);
	const [share, setShare] = useState(null)

	useEffect(()=>{
		const fetchData = async () => {
		    try {
				let response = await fetch(`/chatbots?email=${encodeURIComponent(email)}`);
				let json = await response.json();
				setChatbots(json);
				const hasProcessing = json.some(chatbot => ['processing', 'creating_sitemap'].includes(chatbot.status));
				if ((!hasProcessing && intervalId) || json.length == 0) {
					clearInterval(intervalId);
				}
		    } catch (error) {
		      console.error('Error fetching chatbots:', error);
		    }
		  };

		  if (!mounted) {
		    const id = setInterval(fetchData, 10000);
		    setIntervalId(id);
		    fetchData();
		    setMounted(true);

		    return () => {
		      clearInterval(id);
		    };
		  }
	},[email])

	const goToDemoCreator = () =>{
		navigate('/')
	}

	function openShare(url) {
		setShare(url)
	}

	const closeShare = () =>{
		setShare(null)
	}

	function addHttps(url) {
	    if (!url.startsWith("https://") && !url.startsWith("http://")) {
	        url = "https://" + url;
	    }
	    return url;
	}

	return (
		<div className="relative w-full items-start justify-start min-w-[300px] max-w-[960px] text-left text-3xl text-light-typography-color-body-text font-light-basic-typography-h4-heading">
			<div className="self-stretch bg-light-solid-color-extra-body-background overflow-y-auto flex flex-col items-center justify-center py-6 px-4 sm:px-8 md:px-16 gap-5">
				<div className="self-stretch rounded-xl  flex flex-col items-center justify-center py-3 px-4 md:text-13xl text-lg">
				  <div className="relative font-semibold">
				    See CustomGPT in action with your website
				  </div>
				</div>
				<div className="self-stretch rounded-md bg-light-solid-color-extra-card-background flex flex-col items-start justify-start p-6 gap-[16px]">
					<div className="self-stretch flex flex-col sm:flex-row items-center justify-start">
						<div className="flex-1 relativefont-semibold">
						  Your Demo Custom GPTs
						</div>
						<div className="relative text-mini text-light-solid-color-primary-primary-500-base break-all">
						  {email}
						</div>
					</div>
					<div className="h-80 overflow-y-scroll shadow-md sm:rounded-lg w-full gap-5 flex-col hidden md:flex">
						<table className="w-full text-mini text-left rtl:text-right text-gray-500">
							<thead className="sticky top-0 z-40	text-gray-700 uppercase bg-gray-50">
							  <tr>
							    <th scope="col" className="text-center py-4">
							      Domain
							    </th>
							    <th scope="col" className="text-center py-4">
							      Pages
							    </th>
							    <th scope="col" className="text-center py-4">
							      Status
							    </th>
							    <th scope="col" className="text-center py-4">
							      Chatbot
							    </th>
							  </tr>
							</thead>
							<tbody>
							  {chatbots.map((chatbot, index) => (
							    <tr key={index} className="z-10	bg-white border-b hover:bg-gray-50 h-[60px]">
							      <td className="pl-2 font-medium text-gray-900">
							      	<div className='flex'>
							        <a href={chatbot.url} className="text-gray-900 no-underline">{chatbot.url}</a>
							        <a href={addHttps(chatbot.url)}>
								        <img
								          className="w-[16px] relative cursor-pointer"
								          alt=""
								          src="/open.svg"
								        />
							        </a>
							        </div>
							      </td>
							      <td className="text-center">
							        <div className="flex-1 flex flex-row items-center justify-center px-2">
								        <div>
								        	{chatbot.sitemap ? (
									            <a href={chatbot.sitemap} className="bg-indigo-500 rounded shadow justify-center items-center flex no-underline px-2 py-1 text-xs text-white no-underline">
									               	View Pages
									            </a>
								        	): ''}
								       	</div>
								       	<div>
								        	<a data-tooltip-id="my-tooltip" data-tooltip-content={`We ${chatbot.sitemap ? "selected": "are selecting"}  100 random pages from your website to build the demo custom GPT. Please sign up to build a complete custom GPT with your full website and documents.`}>
												<img className="relative w-4 h-4 ml-1" alt="" src="/infocircle.svg" />
											</a>
										</div>
							        </div>
							      </td>
							      <td className={`text-center text-smi ${statusText[chatbot.status]?.textColor}`}>
							        <div className="flex-1 flex flex-row items-center justify-center px-2">
							          	{['processing', 'creating_sitemap'].includes(chatbot.status) ? (
											<div aria-label="Loading..." role="status">
											  <svg width="15" height="15" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" className="animate-spin w-4 h-4 stroke-orange-500">
											    <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12">
											    </path>
											  </svg>
											</div>
											) : chatbot.status == 'ready' ? (
											<div role="status">
												<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" viewBox="0,0,256,256" className="w-4 h-4 stroke-green-500">
													<g fill="none" fill-rule="nonzero" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(10.66667,10.66667)">
													<path d="M20.29297,5.29297l-11.29297,11.29297l-4.29297,-4.29297l-1.41406,1.41406l5.70703,5.70703l12.70703,-12.70703z"></path></g></g>
												</svg>
											</div>
											) : (<span className="text-red-500 text-mid">x</span>)}
								        <div className={`rounded ${statusText[chatbot.status]?.bgColor} flex flex-row items-center justify-center py-1 px-2.5`}>
								            <div className="relative font-semibold">
								              {statusText[chatbot.status]?.text}
								            </div>
								        </div>
							        </div>
							      </td>
							      <td className="justify-center text-right">
							        <div className="flex-1 flex flex-row items-center justify-center">
							          {chatbot.chatbot_url ? (
							          	<>
								            <div className="w-44 h-14 px-5 border-zinc-300 justify-start items-center gap-2 inline-flex">
											  <a href={chatbot.chatbot_url} className="bg-indigo-500 rounded shadow justify-center items-center flex no-underline" >
											    <div className="px-3.5 py-1.5 justify-center items-center gap-2 flex">
											      <div className="text-white text-xs font-medium leading-none tracking-wide">Chat Now</div>
											    </div>
											  </a>
											  <div className="cursor-pointer rounded border border-solid border-indigo-500 justify-center items-center flex" onClick={()=>openShare(chatbot.chatbot_url)}>
											    <div className="px-3.5 py-1 justify-center items-center gap-1.5 flex">
											      <img
										            className="h-4 w-4 relative min-h-[16px]"
										            alt=""
										            src="/share.svg"
										          />
											    </div>
											  </div>
											</div>
										</>
							          ) : (
							          <a data-tooltip-id="my-tooltip-2" data-tooltip-content={`${chatbot.status === 'processing' ? 'Your custom chatbot is being created. A link will be sent to you once it is ready.' : chatbot.status == 'ready' ? 'You can now talk to your custom GPT chatbot. This demo chatbot will destroy itself in 24 hours': 'Failed to create chatbot'}`}>
											<img className="relative w-4 h-4 ml-1" alt="" src="/infocircle.svg" />
										</a>
										)}
							        </div>
							      </td>
							    </tr>
							  ))}
							</tbody>
						</table>
					</div>
					<Tooltip id="my-tooltip-2" className="z-50 text-smi !w-64 !text-xs" />
					<Tooltip id="my-tooltip" className="z-50 text-smi !w-64 !text-xs" />
					<div className="flex flex-col gap-y-[16px] md:hidden">
						{chatbots.map((chatbot, index) => (
						<div className="rounded-tl-lg rounded-tr-lg border border-solid border-solid border-light-solid-color-extra-divider">
						  <div className="self-stretch flex flex-row flex-wrap items-center justify-evenly border-bottom py-3 px-4">
						      <div className="relative text-mini font-semibold font-light-button-3-button-small text-light-typography-color-body-text text-left">
						        Domain
						      </div>
						      <div className="flex-1 flex flex-col items-start justify-center overflow-hidden">
						        <div className="self-stretch relative text-mini font-light-button-3-button-small text-light-typography-color-body-text text-center overflow-hidden text-ellipsis whitespace-nowrap">
						          <a href={chatbot.url} className="text-black no-underline">{chatbot.url}</a>
						        </div>
						      </div>
						      <div className="flex flex-col items-start justify-start">
						        <a href={addHttps(chatbot.url)}>
							        <img
							          className="w-[16px] relative cursor-pointer"
							          alt=""
							          src="/open.svg"
							        />
						        </a>
						      </div>
						  </div>
						  <div className="self-stretch flex flex-col items-start justify-center py-2 px-4 gap-[8px] text-left text-mini text-light-typography-color-body-text font-light-button-3-button-small">
						      <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[8px]">
						        <div className="flex-1 relative font-semibold inline-block min-w-[46px]">
						          Pages
						        </div>
						        <button className="cursor-pointer [border:none] p-0 bg-light-solid-color-primary-primary-500-base rounded flex flex-row items-center justify-center">
						          <div className="flex flex-row items-center justify-center py-1.5 px-[13px]">
						            <a href={chatbot.sitemap} className="block font-medium text-white no-underline">
						               	View Pages
						            </a>
						          </div>
						        </button>
						      </div>
						      <div className="self-stretch flex flex-col items-start justify-start text-smi text-light-typography-color-muted-text">
						        <div className="">
						          We {chatbot.sitemap ? "selected": "are selecting"}  100 random pages from your website to build the demo custom GPT.
						          Please sign up to build a complete custom GPT with your full website and documents.
						        </div>
						      </div>
						  </div>
						  <div className="self-stretch flex flex-col items-start justify-center py-2 px-4 gap-[8px] text-mini">
						    <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[8px]">
						      <div className="flex-1 relative font-semibold inline-block min-w-[36px]">
						        Status
						      </div>
						      {['processing', 'creating_sitemap'].includes(chatbot.status) ? (
								<div aria-label="Loading..." role="status">
								  <svg width="15" height="15" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg" className="animate-spin w-4 h-4 stroke-orange-500">
								    <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12">
								    </path>
								  </svg>
								</div>
								) : chatbot.status == 'ready' ? (
									<div role="status">
										<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" viewBox="0,0,256,256" className="w-4 h-4 stroke-green-500">
											<g fill="none" fill-rule="nonzero" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(10.66667,10.66667)">
											<path d="M20.29297,5.29297l-11.29297,11.29297l-4.29297,-4.29297l-1.41406,1.41406l5.70703,5.70703l12.70703,-12.70703z"></path></g></g>
										</svg>
									</div>
								) : (<span className="text-red-500 text-mid">x</span>)}
						      <div className={`rounded flex flex-row items-center justify-start py-[5px] px-2.5 text-smi  ${statusText[chatbot.status]?.bgColor} ${statusText[chatbot.status]?.textColor}`}>
						        <div className="flex relative font-semibold">
						        	{statusText[chatbot.status]?.text}
						        </div>
						      </div>
						    </div>
						    <div className="self-stretch flex flex-col items-start justify-start text-smi text-light-typography-color-muted-text">
						      <div className="">
						        {statusText[chatbot.status]?.description}
						      </div>
						    </div>
						    {chatbot.chatbot_url ? (
						    <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[8px]">
								<div className="flex-1 flex flex-row items-center justify-end text-mini pe-1">
								    <a href={chatbot.chatbot_url} className="self-stretch flex flex-1 px-1 py-2 text-white no-underline cursor-pointer rounded-md bg-light-solid-color-primary-primary-500-base items-center justify-center text-center text-xs sm:text-mid">
								      Chat Now
								    </a>
								</div>
						        <button className="cursor-pointer p-0 bg-[transparent] flex-1 rounded box-border flex flex-row items-center justify-center min-w-[93px] border-[1px] border-solid border-light-solid-color-primary-primary-500-base" onClick={()=>openShare(chatbot.chatbot_url)}>
							        <div className="flex flex-row items-center justify-center py-1.5 px-[34px] gap-[6px]">
							          <img
							            className="h-4 w-4 relative min-h-[16px]"
							            alt=""
							            src="/share.svg"
							          />
							          <div className="relative text-smi tracking-[0.43px] font-medium font-light-button-3-button-small text-light-solid-color-primary-primary-500-base text-left">
							            Share
							          </div>
							        </div>
					      		</button>
						    </div>
						    ) : ''}
						  </div>
						</div>
						))}
					</div>
					<div className="self-stretch rounded-md bg-light-solid-color-extra-card-background flex flex-row justify-center gap-[16px]">
						<div className="self-stretch flex flex-col  justify-start w-full">
							<div className="flex-1 relative  font-semibold">
							  Ready to Get Started? Sign Up Now
							</div>
							<div className="flex flex-col md:flex-row text-white rounded-md bg-light-solid-color-extra-body-background flex flex-row items-center justify-evenly p-4 text-mini text-light-solid-color-extra-card-background gap-3">
							    <a href="https://app.customgpt.ai/register" className="h-10 w-full text-white no-underline cursor-pointer rounded-md bg-light-solid-color-primary-primary-500-base flex flex-row items-center justify-center">
							        Sign Up Now
							    </a>

							    <a href="https://app.customgpt.ai/oauth/google" className="h-10 w-full bg-white no-underline cursor-pointer p-0 bg-[transparent] rounded flex flex-row items-center justify-center shadow-md">
									<div className="flex flex-row items-start justify-start gap-[6px]">
									  <img
									    className="relative w-4 h-4 min-h-[16px]"
									    alt=""
									    src="/google.svg"
									  />
									  <div className="relative text-sm font-medium font-light-button-3-button-small text-light-solid-color-primary-primary-500-base text-left">
									    Sign Up with Google
									  </div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{share ? (
				<Modal closeConfirmation={closeShare}>
					<div className="text-center">
						<p className="font-xl">Share</p>
					</div>
					<div className="flex justify-evenly">
						<FacebookShareButton
					          url={share}>
					          <FacebookIcon size={32} round />
					    </FacebookShareButton>
					    <FacebookMessengerShareButton
					          url={share}>
					          <FacebookMessengerIcon size={32} round />
					    </FacebookMessengerShareButton>
					    <TwitterShareButton
					          url={share}>
					          <TwitterIcon size={32} round />
					    </TwitterShareButton>
					    <WhatsappShareButton
					          url={share}>
					          <WhatsappIcon size={32} round />
					    </WhatsappShareButton>
					    <EmailShareButton
					          url={share}>
					          <EmailIcon size={32} round />
					    </EmailShareButton>
					    <LinkedinShareButton
					          url={share}>
					          <LinkedinIcon size={32} round />
					    </LinkedinShareButton>
				    </div>
				</Modal>
			) : ''
			}
		</div>
	);
};

export default StatusChecker;
