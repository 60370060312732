import { useState, useCallback } from "react";
import Confirmation from "../components/Confirmation";
import ProgressBar from "../components/ProgressBar";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Modal from '../components/Modal';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from 'react-responsive';

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 640 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 640, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};
const DemoCreatorWidget = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const [stepText, setStepText] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const csrfToken = document.querySelector("meta[name='csrf-token']").content

  const handleInputChange = (event) => {
    setWebsiteUrl(event.target.value);
    setError('');
  };

  const isValidDomain = (url) => {
    const urlValidatorRegex = /([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/i;
    const isValidURL = urlValidatorRegex.test(url);
    const checkDomain = isValidURL && !url.includes('@')
    return checkDomain
  };

  const startChatbotCreation = async () => {
    setIsLoading(true)
    try{
      let response = await fetch(`https://podcast2sitemap.herokuapp.com/sitemap-finder?url=${websiteUrl}`)
      if(response.status === 200){
        let sitemaps = await response.json()
        if (sitemaps.length > 0){
          let response = await fetch('/chatbot', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': csrfToken
            },
            body: JSON.stringify({ websiteUrl, email, sitemaps }),
          });
          if (!response.ok) {
            setIsLoading(false)
            throw new Error(`Failed with status ${response.status}`);
          }
          const reader = response.body.getReader();
          while (true) {
            const {value, done} = await reader.read();
            if (done) break;
            const jsonString = new TextDecoder('utf-8').decode(value);
            const jsonObject = JSON.parse(jsonString);
            if(jsonObject.status === "error")
              setError(jsonObject.text)
            setStepText(jsonObject.text)
            setStep(jsonObject.step)
          }
          setIsLoading(false)
          console.log('Response fully received');
        }else{
          onFailure()
        }
      }else{
        onFailure()
      }
    }catch(e){
      onFailure()
    }

  };

  async function onFailure(){
    setIsLoading(false)
    let response = await fetch('/stream-chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      body: JSON.stringify({ websiteUrl, email }),
    });

    if (!response.ok) {
      setIsLoading(false)
      throw new Error(`Failed with status ${response.status}`);
    }
    setStep(4)
  }

  const openConfirmation = () => {
    if (!isValidDomain(websiteUrl)) {
      setError('Invalid domain. Please enter a valid URL');
      return;
    }
    setError('')
    setConfirmationOpen(true);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      openConfirmation();
    }
  };

  const closeConfirmation = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  return (
    <>
    <div className="max-w-[960px] min-w-[300px]">
      <div className="relative w-full flex flex-col items-start justify-center sm:text-13xl text-xl text-black">
        <div className="self-stretch bg-light-solid-color-extra-body-background overflow-y-auto flex flex-col items-center justify-center py-6 px-4 sm:px-8 md:px-16 gap-5">
          <div className="self-stretch rounded-xl flex flex-col items-center justify-center text-center py-3 px-4">
            <div className="relative font-semibold">
              See a custom GPT with your website content.
            </div>
          </div>
          <div className="self-stretch rounded-md bg-light-solid-color-extra-card-background flex flex-col p-6 gap-[16px] sm:text-xl text-lg text-gray-600">
            <div className="self-stretch flex flex-row items-center justify-center text-center">
              <div className="flex-1 relative font-semibold leading-5">
                Over 18,000 customers have built custom GPTs with their website
                content
              </div>
            </div>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={false}
              ssr={true}
              responsive={responsive}
              infinite={true}
              autoPlay={isMobile ? true : false}
              customTransition="all 1000ms" 
              transitionDuration={1000}
              autoPlaySpeed={1}
              removeArrowOnDeviceType={["desktop", "mobile"]}
              deviceType={isMobile ? 'mobile' : "desktop"}
            >
              <div>
                <img
                  className="relative object-contain w-[120px] h-[67.5px]"
                  alt=""
                  src="/image 11.png"
                />
              </div>
             <div>
                <img
                  className="relative object-contain w-[120px] h-[67.5px]"
                  alt=""
                  src="/image 12.png"
                />
              </div>
               <div>
                <img
                  className="relative object-contain w-[120px] h-[67.5px]"
                  alt=""
                  src="/image 13.png"
                />
              </div>
               <div>
                <img
                  className="relative object-contain w-[120px] h-[67.5px]"
                  alt=""
                  src="/image 14.png"
                />
              </div>
               <div>
                <img
                  className="relative object-contain w-[120px] h-[67.5px]"
                  alt=""
                  src="/image 15.png"
                />
              </div>

            </Carousel>
            <div className="self-stretch flex flex-col sm:flex-row items-start justify-start text-smi">
                <div className="self-stretch flex rounded-b-none rounded-t-md sm:rounded-l-md sm:rounded-r-none bg-light-solid-color-extra-card-background items-center justify-start border-2 border-solid border-light-solid-color-extra-divider w-[99.3%] sm:w-3/4">
                    <input
                      type="text"
                      className={`rounded text-lg w-full py-2 px-3 text-gray-700 leading-tight border-none ${error ? 'border-red-500' : ''}`}
                      placeholder="Enter your website URL"
                      value={websiteUrl}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                    />
                </div>
                {isLoading ? (
                    <div className="self-stretch items-center justify-evenly py-3 rounded-b-md rounded-t-none sm:rounded-r-md sm:rounded-b-none sm:rounded-l-none e bg-light-solid-color-primary-primary-500-base flex flex-row cursor-pointer text-white w-full sm:w-1/4  text-lg min-w-[70px]">
                      <div role="status">
                        <svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : step === 4 ? (
                    <div className="self-stretch items-center justify-evenly py-3 rounded-b-md rounded-t-none sm:rounded-r-md sm:rounded-b-none sm:rounded-l-none e bg-light-solid-color-primary-primary-500-base flex flex-row cursor-pointer text-white w-full sm:w-1/4 text-base sm:text-lg min-w-[70px]">
                      <div role="status">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" viewBox="0,0,256,256">
                        <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(10.66667,10.66667)"><path d="M20.29297,5.29297l-11.29297,11.29297l-4.29297,-4.29297l-1.41406,1.41406l5.70703,5.70703l12.70703,-12.70703z"></path></g></g>
                        </svg>
                      </div>
                    </div>
                  ) : (
                      <div className="self-stretch items-center justify-evenly rounded-b-md rounded-t-none sm:rounded-r-md sm:rounded-b-none sm:rounded-l-none bg-light-solid-color-primary-primary-500-base flex flex-row cursor-pointer text-white w-full sm:w-1/4 text-mid sm:text-lg min-w-[70px]"
                        onClick={openConfirmation}
                      >
                        <div className="relative font-medium flex py-2">
                          <span className="font-semibold">Continue</span>
                          <img
                            className="w-5"
                            alt=""
                            src="/chevronright.svg"
                          />
                        </div>
                      </div>
                  )}
              </div>
              {error && (
                <div className="text-red-500 text-start text-smi">
                  {error}
                </div>
              )}
              <div className="self-stretch relative text-light-typography-color-body-text text-smi sm:text-lg text-center sm:text-left">
                See your free demo. No credit card required.
              </div>
          </div>
          {step > 0 && (
            <Modal>
            <ProgressBar step={step} text={stepText} email={email} />
            </Modal>
          )}
        </div>
      </div>
      {isConfirmationOpen && (
        <GoogleOAuthProvider clientId="513082693265-2igpm5ok74fpbedfh3kcv25ht2l81nt6.apps.googleusercontent.com">
          <Modal closeConfirmation={closeConfirmation}>
            <Confirmation closeConfirmation={closeConfirmation} email={email} setEmail={setEmail} startChatbotCreation={startChatbotCreation} />
          </Modal>
        </GoogleOAuthProvider>
      )}
    </div>
  </>
  );
};

export default DemoCreatorWidget;
