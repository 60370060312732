import React from 'react';
import { useNavigate } from 'react-router-dom'

const ProgressBar = ({ step, text, email }) => {
  const progressWidth = `${(step - 1) * 33.33}%`;
  const navigate = useNavigate();
  const goToStatus = () => {
  	navigate(`/status-checker/${encodeURIComponent(email)}`)
  }
  return (
    <div className="self-stretch rounded-md bg-white flex flex-col items-start justify-start p-6 gap-[16px]">
      <div className="self-stretch flex flex-row items-start justify-start text-start">
        <div className="flex-1 relative leading-[30px] font-semibold text-gray-500">
          Your Demo Chatbot
        </div>
      </div>
      {step === 4 ? (
      	<div class="flex justify-between items-center flex-col md:flex-row p-4 mb-4 text-smi text-green-800 rounded-lg bg-green-50 gap-3" role="alert">
  		  	<div className="w-full text-center md:text-start">
  		  	  Your custom GPT creation is in progress (usually takes a few
            mins). We will also notify you via email. Please check your spam
            folder and whitelist hello@customgpt.ai .
          </div>
          <button className="text-white border-none rounded bg-light-solid-color-success-success-500-base flex flex-row items-center justify-center cursor-pointer py-2 font-semibold w-[115px]" onClick={goToStatus}>
              Check Status
          </button>
  	    </div>
      ) : (
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-smi">
        <div className="self-stretch relative">
          {text}
        </div>
        <div className="self-stretch h-3 flex flex-col items-start md:justify-start justify-center">
          <div className="self-stretch flex-1 rounded-31xl bg-light-opacity-color-gray-gray-8 overflow-hidden flex flex-row items-start justify-start">
            <div className="self-stretch flex-1 flex flex-row items-start justify-start">
              <div
                className="self-stretch relative rounded-31xl bg-light-solid-color-success-success-500-base"
                style={{ width: progressWidth }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default ProgressBar;
